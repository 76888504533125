import '../css/UserButton.css';
import {logUserButtonClick} from '../api/logUserButtonClick.js';

export const userButton = (userId, buttonId, url, text) => (
  <a
    href={url}
    target="_self"
    rel="follow"
    className="user-button"
    onClick = {(e)=>logUserButtonClick(userId, buttonId)}
    >{text}
    </a>
);


//export const logUserButtonClick = async (userId, buttonId) => {
