
import React from "react"
import { Script } from "react-inline-script";
import { Helmet } from "react-helmet";

const myjst = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-G5EH3RBCRF');
  `
  ;


const GoogleAnalyticsScript = () => {
  return (
    <>
<Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-G5EH3RBCRF"></script>
    <script>{myjst}</script>
</Helmet>
    <Script>
  {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-G5EH3RBCRF');
  `}
</Script>
</>
  )
};

export default GoogleAnalyticsScript;