import axios from "axios";


const clicklog_endpoint = `https://${process.env.REACT_APP_DOMAIN}/api/loguserbuttonclick`;

/**
* userId: PK
* buttonId: SK
*/
export const logUserButtonClick = async (userId, buttonId) => {
   console.log('Received click for ', userId, buttonId);
  const payload = JSON.stringify({
                          userId: userId,
                          buttonId: buttonId
                          });
  navigator.sendBeacon(clicklog_endpoint,payload);
                      }

