import axios from "axios";

/**
 * Log a click an item.  Currently:
 * - Standard posts take mediaId and username.  Note that 'username' is really userId, so should be renamed to userId at some point.
 *      We could just pass mediaId and have the backend lookup the userId.
 * - Child posts, on the other hand, do not require userId due to the way the DDB table is structured, hence we pass a placeholder.
 *
 * Beacon gets called:
 *
 * 1) On a VanillaPost
 * 2) On a PostWithModal
 * 3) On an ImageLinksDev
 *
 */
const clicklog_endpoint = `https://${process.env.REACT_APP_DOMAIN}/api/clicklog`;

const beacon = async (mediaId, userId, url) => {
  const payload = JSON.stringify({
    mediaId: mediaId,
    username: userId,
    url: url,
  });
  navigator.sendBeacon(
    clicklog_endpoint,
    JSON.stringify({
        mediaId: mediaId,
        username: userId,
        url: url
        })
  );
};

export default beacon;
