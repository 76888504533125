import axios from "axios";

/**
 * Retrieve user info based on the username.  Returns
 * the user's IG handle and profile pic.
 * @param  username
 * @returns
 */

 const domain = process.env.REACT_APP_DOMAIN || "www-dev.gotolinks.com";

export const retrieveUser = async (username) => {
  //const endpoint = `https://${process.env.REACT_APP_DOMAIN}/api/userinfo?u=${username}`;
  const endpoint = `https://${domain}/api/userinfo?u=${username}`;
    const user = await axios.get(endpoint);
  return user;
};
