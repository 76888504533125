import React from "react";
import "../css/Grid.css";
import {userButton} from './UserButton.js';

const Header = ({ user }) => {
const userButtons = user.userButtonList
  .slice() // optional: clone the array to avoid mutating the original
  .sort((a, b) => a.order - b.order)
  .map((item, index) => (
    <div key={index}>
      {userButton(user.userId, item.buttonId, item.url, item.text)}
    </div>
  ));

console.log(user.userButtonList);

 const testButton = userButton('https://www.google.com', 'Google');
  const handle = `@${user.username}`;
  return (
    <header>
      <table>
        <tbody>
          <tr>
            {user.profilePicUrl !== undefined ? (
              <td>
                <img
                  src={user.profilePicUrl}
                  alt="Profile"
                  className="avatar"
                />
              </td>
            ) : null}
            <td>
              <p className="ig-handle">{handle}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p className="call-to-action">
        <b>{user.welcome}</b>
      </p>



      <p className="homepage-disclosure">{user.profilePageDisclosure}</p>
    {userButtons}
    </header>
  );
};

export default Header;
