import "./App.css";
import { useEffect, useState, useLayoutEffect } from "react";
import Header from "./components/Header";
import TabGroup from "./components/TabGroup";
import "../src/css/Grid.css";
import "../src/css/Cookie.css";
import Main from "./components/Main";
import GoogleAnalyticsScript from "./components/GoogleAnalyticsScript";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel"; // Import react-facebook-pixel
import {postPixel} from './api/postPixel.js';
import Cookies from "js-cookie"; 
import ErrorPage from "./error-page";

const googleAnalyticsEnabled = process.env.ENABLE_GOOGLE_ANALYTICS === "true";

const getCookieValue = (key) => {
  // Use js-cookie to read the cookie value
  return Cookies.get(key);
};

function getEventId(user) {
//  return  'event_' + user.userId.replace('userId#','') + '_'  + new Date().getTime();
    return  'event_'   + new Date().getTime();

}

function App() {

  const user = useLoaderData();
  const location = useLocation(); // Access the location object for route info
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);

 
  // Determine the initial tab based on user settings and query parameters
  const determineInitialTab = () => {
    if (user.tiktokEnabled && !user.fbEnabled) {
      return "TIKTOK";
    }
    if (user.fbEnabled && !user.tiktokEnabled) {
      return "INSTAGRAM";
    }
    return query.get("tab") === "tiktok" ? "TIKTOK" : "INSTAGRAM";
  };

  const [selectedSource, setSelectedSource] = useState(determineInitialTab);

useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const referrer = document.referrer;
 console.log(`referrer=${referrer} v2.1`);

    if (!query.has("tab")) {

      if (
        userAgent.includes("TTWebView") ||
        userAgent.includes("TikTok") ||
        referrer.includes("tiktok.com") ||
        referrer.includes("chipmunk")
      ) {
//        navigate(`?tab=tiktok`, { replace: true });
//            window.location.replace(`?tab=tiktok`);

      }
    }
  }, [query, navigate]);

  // Prevent redundant navigation and infinite loop
  useEffect(() => {
    const currentTab = query.get("tab");
    if (
      (selectedSource === "TIKTOK" && currentTab !== "tiktok") ||
      (selectedSource === "INSTAGRAM" && currentTab)
    ) {
      navigate(selectedSource === "TIKTOK" ? `?tab=tiktok` : "", { replace: true });
    }
  }, [selectedSource, location.search, navigate]);

  const handleTabChange = (source) => {
    setSelectedSource(source);
  };

    useLayoutEffect(()=> {
        clearFacebookCookies();
    }, [user]);

   useEffect(() => {
    initPixel(user);
  }, [user, location.pathname]);


  // Conditional rendering logic
  const showTabGroup = user.fbEnabled && user.tiktokEnabled;
  // Render ErrorPage if base_subscription is 'NONE'
  if (user.base_subscription === 'NONE') {
    return (
      <>
        {googleAnalyticsEnabled &&<GoogleAnalyticsScript />}
        <ErrorPage />
      </>
    );
  }
  return (
  <>
    {googleAnalyticsEnabled && <GoogleAnalyticsScript />}

    <Header user={user} />

    {showTabGroup && (
      <TabGroup onTabChange={handleTabChange} activeTab={selectedSource} />
    )}

    {/* Always render both Main components, but hide one */}
    <div style={{ display: selectedSource === "INSTAGRAM" ? "block" : "none" }}>
      {user.fbEnabled && <Main user={user} source="INSTAGRAM" />}
    </div>

    <div style={{ display: selectedSource === "TIKTOK" ? "block" : "none" }}>
      {user.tiktokEnabled && <Main user={user} source="TIKTOK" />}
    </div>
  </>
  );
}


  const initPixel = (user)=>{
     if (user.metaPixelId && user.metaPixelId.length > 0) {
          //  ReactPixel.pageView(); // Track page view
          ReactPixel.init(user.metaPixelId);
         const event_id = getEventId(user);
         const fbp = getCookieValue("_fbp");

          ReactPixel.trackSingle(user.metaPixelId, 'PageView',
            {
              event_id: event_id,
            });
          //Make this call conditional based on if they have a Conversions API Token.
          postPixel(user.userId, event_id,fbp);
    } else {
        //If no pixel defined, clear it
        clearFacebookCookies();
    }
  };

const clearFacebookCookies = ()=> {
       const domain =
          window.location.hostname === "localhost" ? "localhost" : "gotolinks.com";

        document.cookie = `_fbp=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure;`;
        document.cookie = `_fbc=; path=/; domain=${domain}; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure;`;

}

export default App;
