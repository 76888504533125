import React from "react";
import "../css/Grid.css";


const types = ["INSTAGRAM", "TIKTOK"];

function TabGroup({ onTabChange, activeTab }) {
  return (
    <div className="button-group">
      {types.map((type) => (
        <button
          key={type}
          className={`tab ${activeTab === type ? "active" : ""}`}
          onClick={() => onTabChange(type)}
        >
          {type}
        </button>
      ))}
    </div>
  );
}

export default TabGroup;
